import React, { useEffect } from 'react'
// import parse from 'html-react-parser';

const Hero = ({ title, video }) => {

  const videoId = video.match(/vimeo\.com\/(\d+)/)?.[1];

  // const embedUrl = `https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0&muted=1&autoplay=1&autopause=0&controls=1&dnt=1&loop=1&background=1&quality=1080`;
     const embedUrl = `https://player.vimeo.com/video/${videoId}?title=0&byline=0&portrait=0&muted=1&autoplay=1&autopause=0&controls=1&dnt=1&loop=1&quality=1080`;
  // "https://player.vimeo.com/video/510935163?title=0&byline=0&portrait=0&muted=1&autoplay=1&autopause=0&controls=0&dnt=1&loop=1&background=1&quality=1080&app_id=122963"

  return (
    <div className="hero-container grid" data-scroll-section>
      <div className="video-container">
        <iframe
          src={embedUrl}
          width="640"
          height="273"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          title={"Gutiérrez F Studio"}
        ></iframe>
      </div>
      <h2 data-scroll data-scroll-speed="1" data-sal='fade' data-sal-delay='350' data-sal-duration='750' data-sal-easing='ease'>{title.translate}</h2>
    </div>
  )
}

export default Hero
